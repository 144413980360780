import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios"


const AgencyAdminHostList = ({ status }) => {
    const baseUrl = process.env.REACT_APP_BASEURL1

  const [list, setList] = useState([])

  const {
    getHosts,
    totalHosts,
    aprrovedHost,
    totalPages,
    setTotalPages,
    page,
    setPage,
  } = useContext(global);
  const [abc, setAbc] = useState(0);
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);
  const agencysqlId = newAgency?.sqlId
  const agencyuniqueId = newAgency?.uniqueId
  
 
console.log();
  let id;


  // console.log(apidata)
  if (newAgency?.old == "1") {
    id = newAgency.sqlId;
  } else {
    id = newAgency?.uniqueId;
  }

  const filterData = totalHosts?.filter((d) => d.status !== status);

  useEffect(() => {
    getHosts(id, status);
  },[status,abc]);
//   ==========================
const agencyadminlist = async ()=>{
    const response = await axios.get(`https://mastishortss.com/video/agency/host/getpenbdlist`)
    setList(response.data.details)
    console.log(response.data.details)

}
useEffect(() => {
 
 agencyadminlist()
}, [])

// =============================
  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Agency Code</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {list.map((host, i) => (
                      <tr key={host._id}>
                        <td>{i + 1}</td>
                        <td>{host?.userId.username}</td>
                        <td>{host?.userId.realName}</td>
                        <td>{host?.agencyDetails[0].uniqueId}</td>
                        <td>
                          <span
                            className={`label  ${
                              host?.agencyadminstatus === 0
                                ? "label-info"
                                : host?.agencyadminstatus == 1
                                ? "label-success" 
                                : host?.agencyadminstatus == 3
                                ? "label-primary" 
                              : "label-danger"
                            }`}
                          >
                            {status == 0
                              ? "Pending"
                              : status == 1
                              ? "Aprroved"
                              : status == 3
                              ? "Aprroved"
                              : "Rejected"}
                          </span>
                        </td>
                        <td>
                          <ul
                            className="admin-action btn btn-default"
                            style={{
                              backgroundColor: "#f4f4f4",
                              color: "#fff !important",
                            }}
                          >
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                style={{ color: "black" }}
                                data-toggle="dropdown"
                                href="#"
                                aria-expanded="false"
                              >
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                {status === 0 ? (
                                  <>
                                    <li
                                      role="presentation"
                                      onClick={async() => {
                                        setAbc(abc + 1);
                                        await aprrovedHost({
                                          id: host._id,
                                          status: 1,
                                        });
                                        await getHosts(id, status);

                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Aprroved
                                      </Link>
                                    </li>
                                    <li
                                      role="presentation"
                                      onClick={async() => {
                                        setAbc(abc + 1);
                                        await aprrovedHost({
                                          id: host._id,
                                          status: 2,
                                        });
                                        await getHosts(id, status);

                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Reject
                                      </Link>
                                    </li>
                                  </>
                                ) : status ==1 ? (
                                  <>
                                  <li
                                  role="presentation"
                                >
                                  <Link role="menuitem" tabIndex={-1} to={`/agency/viewUser?old=${host.old}&id=${host.userId}`}>
                                    View
                                  </Link>
                                </li>
                                  <li
                                    role="presentation"
                                    onClick={async() => {
                                      await aprrovedHost({
                                        id: host._id,
                                        status: 2,
                                      });
                                      setAbc(abc + 1);
                                      await getHosts(id, status);

                                    }}
                                  >
                                    <Link role="menuitem" tabIndex={-1} to="">
                                      Reject
                                    </Link>
                                  </li>
                                  </>

                                ) : (
                                  <li
                                    role="presentation"
                                    onClick={async() => {
                                      await aprrovedHost({
                                        id: host._id,
                                        status: 1,
                                      });
                                      await getHosts(id, status);

                                    }}
                                  >
                                    <Link role="menuitem" tabIndex={-1} to="">
                                      Aprroved
                                    </Link>
                                  </li>
                                )}
                       
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div>
                  <ul className="pagination">
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page > 1) {
                            setPage((prev) => prev - 1);
                          }
                        }}
                      >
                        Previous
                      </span>
                    </li>
                    {/* Example pagination links */}

                    {Array.from({ length: totalPages }).map((el, i) => (
                      <li className={`${page === i + 1 ? "active" : ""}`}>
                        <a
                          href=""
                          onClick={() => {
                            setPage(el);
                          }}
                        >
                          {i + 1}
                        </a>
                      </li>
                    ))}

                    {/* Add more pagination links as needed */}
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (page < totalPages) {
                            setPage((prev) => prev + 1);
                          }
                        }}
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AgencyAdminHostList