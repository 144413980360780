import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
const ViewUserr = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [userData, setUserData] = useState([]);

  const [readOnly, setReadOnly] = useState(true); // State variable to track readonly status
  // const [userData, setUserData] = useState({});
  const toggleReadOnly = () => {
    setReadOnly(!readOnly); // Toggle readonly status
  };
  const getdata = async () => {

    // Create FormData object from the form element


    const response1 = await axios.get(
      `https://mastishortss.com/video/agency/host/getUser?old=${old}&userId=${id}`,
    );
    setUserData(response1.data.details);

    
  };
 

  //   For query
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const old = searchParams.get("old");
 
 useEffect(()=>{
  getdata();
 },[])

 

 
  return (
    <>
      <section className="content-header">
        <h1>View User</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageUser">
              <i className="fa fa-user"></i> User
            </Link>
          </li>
          <li className="active">View User</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"

            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${baseUrl}${userData?.picture}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </div>
                  <div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData.username}{" "}
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Normal User
                      </span>
                    </div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData.bio != "" ? <>{userData.bio}</> : <>N/A</>}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Username*</label>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        defaultValue={userData.username}

                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        defaultValue={userData.name}

                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bio*</label>
                      <input
                        type="text"
                        name="bio"
                        className="form-control"
                        defaultValue={userData.bio}
                      value={userData.bio}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Gender*</label>
                      <input
                        type="text"
                        name="gender"
                        className="form-control"
                        defaultValue={userData.gender}

                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                 
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Recieving Coins*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        defaultValue={userData.recievingCoins}
                        
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Coins*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        defaultValue={userData.coin}
                        
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  {/* Official Tag */}
                 
                  {/* UserId(Ban/ Unban) */}
                 
                  {/* Device Id(Ban/ Unban) */}
                 
               
                </div>
              </div>
             
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewUserr;
