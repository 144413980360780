import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Video = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}admin/video`);
        setVideos(response.data.details);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    }; 

    fetchData();
  }, [baseUrl]);

  const indexOfLastVideo = currentPage * 5;
  const indexOfFirstVideo = indexOfLastVideo - 5;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (videoId) => {
    try {
      await axios.delete(`${baseUrl}admin/video/${videoId}`);
      // If successful, remove the deleted video from the state
      setVideos(videos.filter(video => video._id !== videoId));
    } catch (error) {
      console.log("Error deleting video:", error);
    }
  };

  const totalPages = Math.ceil(videos.length / 5);

  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Thumbnail</th>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentVideos.map((item, index) => {
                      let i = index + 1 + (currentPage - 1) * 5;
                      return (
                        <tr key={item._id}>
                          <td>{i}</td>
                          <td>{item?.userId.username}</td>
                          <td>
                            <img
                              src={`${item?.thumbnail}`}
                              alt="Thumbnail"
                              style={{
                                height: "100px",
                                width: "100px",
                              }}
                            />
                          </td>
                          <td className="d-flex border justify-content-center">
                            {item?.userId.name}
                          </td>
                          <td>
                            <ul className="admin-action btn btn-default">
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  style={{ color: "black" }}
                                  data-toggle="dropdown"
                                  href="#"
                                  aria-expanded="false"
                                >
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  <li role="presentation">
                                    <Link
                                      role="menuitem"
                                      tabIndex={-1}
                                      to=""
                                      onClick={() => {
                                        // Handle view action
                                      }}
                                    >
                                      View
                                    </Link>
                                  </li>
                                  <li role="presentation">
                                    <Link
                                      role="menuitem"
                                      tabIndex={-1}
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      Delete
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {[...Array(totalPages).keys()].map((pageNumber) => (
                        <li
                          key={pageNumber + 1}
                          className={`page-item ${
                            currentPage === pageNumber + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            onClick={() => paginate(pageNumber + 1)}
                            className="page-link"
                          >
                            {pageNumber + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Video;
