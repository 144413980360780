import React , { useContext, useEffect } from 'react'
import { Link ,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import { global } from '../../Context/Context';

const EditLiveGiftCategory = () => {

    const { fetchlivegift, fetchlivegiftS } = useContext(global)

    useEffect(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const userId = params.get('id');

        $('#form')[0].reset(); // Reset the form using jQuery
        fetchlivegift(userId);
    }, []);

    const navigate = useNavigate();

    const subbbb = (e) => {
        e.preventDefault();
        var a = $("input[name='title']").val();
        var b = $("input[name='id']").val();
        $.ajax({
            url: `${process.env.REACT_APP_BASEURL}/admin/livegiftcategory/updateLiveGiftscategory`,
            type: "post",
            dataType: "json",
            contentType: "application/json", // Set content type to JSON
            data: JSON.stringify({ title : a , id : b }), // Convert object to JSON string
            success: function(res) {
                if (res.success === 1) {
                toast.success(res.message);
                setTimeout(() => {
                    navigate('/admin/manageLiveGiftCategory');
                }, 1000);
                } else {
                toast.error(res.message);
                }
            },
            error: function(xhr, status, error) {
                // Handle error if needed
                console.error(error);
            }
            });
    }

    return (
        <>
            <section className="content-header">
                <h1>Edit LiveGiftCategory</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageLiveGiftCategory">Manage LiveGiftCategory</Link></li>
                    <li className="active">Edit LiveGiftCategory</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12"> 
                        <form role="form" method="post"  onSubmit={subbbb} encType="multipart/form-data" id='form'>
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Edit</h3>
                                </div>
                                <div className="box-body">
                                    <input type="hidden" name='id' defaultValue={fetchlivegiftS._id} />
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" name="title" placeholder="Title" defaultValue={fetchlivegiftS.title} />
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditLiveGiftCategory