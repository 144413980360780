import React from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";

const AddAgency = () => {
  const navigate = useNavigate();
  const subbbb = (e) => {
    e.preventDefault();

    var a = $("input[name='name']").val();
    var b = $("input[name='email']").val();
    var c = $("input[name='number']").val();
    var d = $("input[name='password']").val();
    var e = $("input[name='image']")[0].files[0];
    var f = $("input[name='bd']").val();
    var g = $("input[name='admin']").val();

    let text = "This field cannot be empty";
    if (a == "") {
      $(".form-error1").html(text);
    }
    if (b == "") {
      $(".form-error2").html(text);
    }
    if (c == "") {
      $(".form-error3").html(text);
    }
    if (d == "") {
      $(".form-error4").html(text);
    }
    if (e == "") {
      $(".form-error5").html(text);
    }
    if (f == "") {
      $(".form-error5").html(text);
    }
    if (g == "") {
      $(".form-error5").html(text);
    } else {
      let formData = new FormData();
      formData.append("name", a);
      formData.append("email", b);
      formData.append("number", c);
      formData.append("password", d);
      formData.append("image", e);
      formData.append("bd", f);
      formData.append("admin", g);

      $.ajax({
        url: `${process.env.REACT_APP_BASEURL}/admin/agencyPanel/addAgencyPanel`,
        type: "post",
        dataType: "json",
        data: formData,
        contentType: false,
        processData: false,
        success: function (res) {
          if (res.success === 1) {
            toast.success(res.message);
            // setTimeout(() => {
            //   navigate("/admin/manageAgency");
            // }, 2000);
          } else {
            toast.error(res.message);
          }
        },
      });
    }
  };

  return (
    <>
      <section className="content-header">
        <h1>Add Agency</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageAgency">Manage Agency</Link>
          </li>
          <li className="active">Add Agency</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Agency Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Agency Name"
                      onChange={() => {
                        $(".form-error1").html("");
                      }}
                    />
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Email*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="Agency Email"
                      onChange={() => {
                        $(".form-error2").html("");
                      }}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Mobile*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      placeholder="Agency Mobile"
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>BD*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bd"
                      placeholder="BD uniqueId"
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Admin*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="admin"
                      placeholder="Admin uniqueId"
                      onChange={() => {
                        $(".form-error3").html("");
                      }}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Password*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      placeholder="Agency Password"
                      onChange={() => {
                        $(".form-error4").html("");
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Agency Image*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5  text-danger"></div>
                  </div>
                  {/* Repeat other form fields as needed */}
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddAgency;
