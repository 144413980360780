import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const FamilyCount = () => {
  const [editable, setEditable] = useState(false);
  const [Image, setImage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [Id, setId] = useState("");
  const [count, setcount] = useState("");
  const [inputValue, setInputValue] = useState("");
  const baseUrl = process.env.REACT_APP_BASEURL1;

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    setInputValue(""); // Reset input value on cancel
  };

  const handleSaveClick = () => {
    setEditable(false);
    // Perform save operation here with inputValue
    // For example, you can send it to an API or update state in parent component
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const data1 = async () => {
    try {
      const data = await axios.get(`${baseUrl}/admin/familyImage/image`);

      setcount(data.data.details[0].count);
      setImage(data.data.details[0].image);
      setId(data.data.details[0]._id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();

      if (inputValue.trim() !== "") {
        formData.append("count", inputValue);
      } else {
        formData.append("count", count);
      }
      formData.append("image", newImage);

      const editdata = await axios.post(
        `${baseUrl}/admin/familyImage/update?id=${Id}`,

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(editdata);
      toast.success("Agency Admin details updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Agency Admin details update failed");
    }
  };
  useEffect(() => {
    data1();
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>Manage Family Count</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageTag">Manage Family</Link>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Manage</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Family Count*</label>
                    {editable ? (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control"
                            name="count"
                            placeholder="Title"
                            defaultValue={count}
                            value={inputValue}
                            onChange={handleChange}
                            style={{ width: "60%" }}
                          />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            placeholder="select Image"
                            // value={inputValue}
                            onChange={(e) => {
                              // console.log(e.target.files);
                              setNewImage(e.target.files[0]);
                            }}
                            style={{ width: "60%" }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger "
                            style={{ marginLeft: "20px", marginRight: "10px" }}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Title"
                            value={count}
                            style={{ width: "60%" }}
                            readOnly
                          />

                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: "20px" }}
                            onClick={handleEditClick}
                          >
                            Edit
                          </button>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />

                    <img src={`${baseUrl}${Image}`} alt="" height={"80px"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyCount;
