import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";

const Vulgar = () => {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const { getVulgarResult, getVulgar, aproveRejectVulgar } = useContext(global);
  const [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const filterData = getVulgar.filter((f) => f._id !== id);
  useEffect(() => {
    return () => {
      getVulgarResult();
    };
  }, []);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const currentUsers = getVulgar?.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math?.ceil(getVulgar?.length / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Username</th>
                      <th>Video</th>
                      <th>Safe %</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {Array.isArray(filterData) &&
                      filterData.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>{item?.userId?.username}</td>
                            <td>
                              <video
                                src={`${baseUrl}${item?.video}`}
                                autoPlay
                                loop={true}
                                controls={false}
                                muted={true}
                                style={{
                                  height: "300px",
                                  width: "300px",
                                }}
                              ></video>
                            </td>
                            <td className=" d-flex border justify-content-center">
                              {item?.lastSafeValue.length > 0
                                ? item?.lastSafeValue * 100 + "%"
                                : "null"}
                            </td>
                            <td>
                              {item.status == "0" ? (
                                <td>
                                  <span className="label label-info">
                                    Pending
                                  </span>
                                </td>
                              ) : item.status == 1 ? (
                                <td>
                                  <span className="label label-success">
                                    Approved
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <span className="label label-danger">
                                    Rejected
                                  </span>
                                </td>
                              )}
                            </td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "0",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            aproveRejectVulgar(obj);
                                          }}
                                        >
                                          Approve
                                        </Link>
                                      </li>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "3",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            aproveRejectVulgar(obj);
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </li>
                                      <li role="presentation">
                                        <Link
                                          role="menuitem"
                                          tabIndex={-1}
                                          to=""
                                          onClick={() => {
                                            const obj = {
                                              status: "2",
                                              id: item._id,
                                            };
                                            setId(item._id);
                                            aproveRejectVulgar(obj);
                                          }}
                                        >
                                          Reject
                                        </Link>
                                      </li>
                                    </>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Repeat this row structure for each data item */}
                  </tbody>
                </table>
                {/* Pagination links */}

                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries: <b>{getVulgar?.length}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vulgar;
