import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { useSearchParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
const ViewUser = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [tag, setTag] = useState([]);
  const {
    // user,
    viewUsers,
    viewUser,
    viewTagg,
    viewTag,
    adminframes,
    viewadminframes,
    adminentryEffects,
    viewadminentryEffects,
  } = useContext(global);
  const { banStatus } = useContext(global);
  const [readOnly, setReadOnly] = useState(true); // State variable to track readonly status
  // const [userData, setUserData] = useState({});
  const toggleReadOnly = () => {
    setReadOnly(!readOnly); // Toggle readonly status
  };
  // const subbbb = async (e) => {
  //   e.preventDefault();
  //   const formElement = document.getElementById("specialitiesForm");
  //   // Create FormData object from the form element
  //   let formData = new FormData(formElement);
  //   console.log(id);
  //   const data2 = await axios.post(
  //     `https://mastishortss.com/video/admin/edituser?id=${id}`,

  //     formData
  //   );
  //   console.log(data2);
  //   console.log(id);
  // };
  const subbbb = async (e) => {
    e.preventDefault();
    // const formElement = document.getElementById("specialitiesForm");
    // let formData = new FormData(formElement);
    try {
      console.log(id);
      const response1 = await axios.post(
        // `https://mastishortss.com/video/admin/edituser?id=${id}`,
        `${baseUrl}/admin/edituser?id=${id}`,
        // formData
        userDetails
      );
      setUserData(response1.data);
      if (response1.data.success == "1") {
        toast.success(response1.data.message);
        toggleReadOnly();
      } else {
        toast.error(response1.data.message);
        // toggleReadOnly()
      }

      console.log(response1);
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error(error.message);
    }
  };

  const handlechange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  //   For query
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const response = async () => {
    const data1 = await axios.get(`${baseUrl}/admin/view_user?id=${id}`);

    setUserData(data1.data.details);
    // toast.success(data1.data.message);
  };
  const settag = async (tag) => {
    if (tag !== null) {
      try {
        const data2 = await axios.post(`${baseUrl}/admin/usertag?id=${id}`, {
          myTag: tag,
          // banStatus: banstats,
        });
      } catch (error) {
        console.error("Error updating user tag:", error);
        // Handle error as needed
      }
    } else {
      console.error("Tag value is null, skipping update");
    }
  };
  // banstatus
  const updateUserBanStatus = async (banStatusValue) => {
    try {
      const val = { ...userData, banStatus: banStatusValue };

      const data = await axios.post(`${baseUrl}/admin/userban?id=${id}`, val);
    } catch (error) {
      console.error("Error updating user ban status:", error);
    }
  };

  useEffect(() => {
    // response();
    viewTagg();
    viewadminframes();
    viewadminentryEffects();
    return () => {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const userId = params.get("id");
      viewUsers(userId);
    };
  }, []);
  useEffect(() => {
    response();
  }, [subbbb]);

  const createdAtDate =
    userData && userData.createdAt ? new Date(userData.createdAt) : null;

  const formattedDate = createdAtDate
    ? createdAtDate.toISOString().split("T")[0]
    : "";
  console.log(userDetails);
  return (
    <>
      <section className="content-header">
        <h1>View User</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageUser">
              <i className="fa fa-user"></i> User
            </Link>
          </li>
          <li className="active">View User</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
              onSubmit={subbbb}
            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${baseUrl}${userData?.picture}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </div>
                  <div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData.username}{" "}
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Normal User
                      </span>
                    </div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData.bio != "" ? <>{userData.bio}</> : <>N/A</>}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Username*</label>
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        defaultValue={userData.username}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Name*</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        defaultValue={userData.name}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bio*</label>
                      <input
                        type="text"
                        name="bio"
                        className="form-control"
                        defaultValue={userData.bio}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Gender*</label>
                      <input
                        type="text"
                        name="gender"
                        className="form-control"
                        defaultValue={userData.gender}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        defaultValue={`XXXXX-${userData.number}`}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Join Date*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={formattedDate}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Videos*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={userData.videos}
                        // onChange={handlechange}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="text"
                        name="password"
                        className="form-control"
                        defaultValue={userData.password}
                        onChange={handlechange}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Followers*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={userData.following}
                        // onChange={handlechange}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Following*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={userData.follow}
                        // onChange={handlechange}
                        readOnly
                      />
                    </div>
                  </div>
                  {/* Official Tag */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Official Tags
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                        onChange={(e) => {
                          const selectedTagId = e.target.value;
                          setTag(selectedTagId);
                          settag(selectedTagId);
                        }}
                      >
                        <option disabled selected>
                          Select Tag
                        </option>
                        {Array.isArray(viewTag) &&
                          viewTag.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  {/* UserId(Ban/ Unban) */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tagggg"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        UserId(Ban/ Unban)
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tagggg"
                        style={{ border: "1px solid #ef5d84" }}
                        onChange={() => {
                          const selectedBanStatus = $("#o-tagggg").val();
                          updateUserBanStatus(selectedBanStatus);

                          // setBanStatus(selectedBanStatus);
                        }}
                      >
                        <option disabled>UserId(Ban/ Unban)</option>
                        <option
                          value={0}
                          selected={
                            viewUser?.banStatus === "0" ||
                            viewUser?.banStatus === ""
                          }
                        >
                          unbanned
                        </option>
                        <option
                          value={1}
                          selected={viewUser?.banStatus === "1"}
                        >
                          banned
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* Device Id(Ban/ Unban) */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Device Id(Ban/ Unban)
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Device Id Banned
                        </option>
                        <option value={1}>Device Id Not Banned</option>
                      </select>
                    </div>
                  </div>
                  {/* Send Frame */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Send Frame
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Select Frame
                        </option>
                        {Array.isArray(adminframes) &&
                          adminframes.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div> */}
                  {/* Send Garage */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Send Garage
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Select EntryEffect
                        </option>
                        {Array.isArray(adminentryEffects) &&
                          adminentryEffects.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-2 col-sm-10">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={toggleReadOnly}
                  >
                    {readOnly ? "Edit Profile" : "Cancel"}
                  </button>
                  {!readOnly ? (
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ marginLeft: "20px" }}
                      onClick={toggleReadOnly}
                    >
                      Save Changes
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewUser;
