import React, { useContext, useEffect, useState } from "react";

import { toast } from "react-toastify";
// import { global } from "../../Context/Conte
import axios from "axios";
const AaProfile = () => {
  const parsdata = sessionStorage.getItem("agencyadmin");
  const data = JSON.parse(parsdata);
  const id = data._id;
  const [agencyadmin, setagencyadmin] = useState({
    name: data.name,
    email: data.email,
    number: data.number,
    password: "",
    oldpassword: "",
    confirmpassword: "",
    newpassword: "",
    image: "",
  });
  const BaseUrl = process.env.REACT_APP_BASEURL1;

  const [readOnly, setReadOnly] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setagencyadmin((prevState) => ({
        ...prevState,
        image: e.target.files[0],
      }));
    } else {
      const { name, value } = e.target;
      setagencyadmin((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const toggleReadOnly = () => {
    setReadOnly(!readOnly);
  };
  const subbbb = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      console.log(formData);
      formData.append("name", agencyadmin.name);
      formData.append("email", agencyadmin.email);
      formData.append("number", agencyadmin.number.replaceAll(" ",""));
      formData.append("image", agencyadmin.image);

      const res = await axios.put(
        `${BaseUrl}/agencadminpanel/updateagencyadmin?id=${id}`,
        formData
      );
      console.log(res.data);
      toast.success(res.data.message);
      sessionStorage.setItem("agencyadmin", JSON.stringify(res.data.details));

      toggleReadOnly();

      // toast.error("Unable to update");

      // console.log(agencyadmin);
    } catch (error) {
      console.log(error);
    }
  };
  ////////////// updatepassword///////////////
  const handlesubmit = async (e) => {
    try {
      e.preventDefault();
      let formData = new FormData();
      console.log(formData);
      console.log(id);
      formData.append("oldpassword", agencyadmin.oldpassword);
      formData.append("newpassword", agencyadmin.newpassword);
      formData.append("confirmpassword", agencyadmin.confirmpassword);
      
      const data = await axios.put(
        `${BaseUrl}/agencyadmin/upatepassword?id=${id}`,
        agencyadmin
      );
      console.log(data);

      toast.success(data.data.message);
      console.log(data.details)
      sessionStorage.setItem("agencyadmin", JSON.stringify(data.data.details));
    } catch (error) {
      console.log(error);
    }
  };
  ////////////////////////////////////////////
  useEffect(() => {
    toggleReadOnly();
  }, []);
  // });
  // };
  return (
    <>
      <section className="content-header">
        <h1>User Profile</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">
              <i className="fa fa-dashboard" /> Home
            </a>
          </li>
          <li className="active">Agency Admin Profile</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-3">
            <div className="box box-primary">
              <div className="box-body box-profile">
                <img
                  className="profile-user-img img-responsive img-circle"
                  style={{ height: "150px", width: "150px" }}
                  src={`${BaseUrl}${data.image}`}
                  alt="Ageny Admin profile picture"
                />
                {/* <h3 className="profile-username text-center">{details.name}</h3> */}
                {/* <p className="text-muted text-center">{details.bio}</p> */}
              </div>
            </div>
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">About Me</h3>
              </div>
              <div className="box-body">
                <strong>
                  <i className="fa fa-phone margin-r-5" /> Phone Number
                </strong>
                <p className="text-muted">{data.number}</p>
                <hr />
                <strong>
                  <i className="fa fa-envelope margin-r-5" /> Email
                </strong>
                <p className="text-muted">{data.email}</p>
                <hr />
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="nav-tabs-custom">
              <ul className="nav nav-tabs">
                <li className="active">
                  <a href="#settings" data-toggle="tab">
                    Settings
                  </a>
                </li>
                <li>
                  <a href="#changePassword" data-toggle="tab">
                    Change Password
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="active tab-pane" id="settings">
                  {/* form  */}
                  <form
                    className="form-horizontal"
                    method="post"
                    onSubmit={subbbb}
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label
                        htmlFor="inputName"
                        className="col-sm-2 control-label"
                      >
                        Name
                      </label>
                      <input type="hidden" defaultValue={data._id} name="id" />
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="name"
                          defaultValue={data.name}
                          className="form-control"
                          id="inputName"
                          placeholder="Master Panel"
                          onChange={handleChange}
                          readOnly={readOnly}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputEmail"
                        className="col-sm-2 control-label"
                      >
                        Email
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          name="email"
                          defaultValue={data.email}
                          className="form-control"
                          id="inputEmail"
                          placeholder="Email"
                          readOnly={readOnly}
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputPhone"
                        className="col-sm-2 control-label"
                      >
                        Phone
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="number"
                          name="number"
                          defaultValue={data.number}
                          className="form-control"
                          id="inputPhone"
                          placeholder="Phone"
                          readOnly={readOnly}
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="inputImage"
                        className="col-sm-2 control-label"
                      >
                        Image
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="file"
                          name="image"
                          className="form-control"
                          id="inputImage"
                          readOnly={readOnly}
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={toggleReadOnly}
                        >
                          {readOnly ? "Edit Profile" : "Cancel"}
                        </button>
                        {!readOnly ? (
                          <button
                            type="submit"
                            onClick={subbbb}
                            className="btn btn-success"
                            style={{ marginLeft: "20px" }}
                          >
                            Save Changes
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="changePassword">
                  <form className="form-horizontal" onSubmit={handlesubmit}>
                    <div className="form-group">
                      <label
                        htmlFor="inputOld"
                        className="col-sm-2 control-label"
                      >
                        Old Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="oldpassword"
                          className="form-control"
                          id="currp"
                          placeholder="Old Password"
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNew"
                        className="col-sm-2 control-label"
                      >
                        New Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="newpassword"
                          className="form-control"
                          id="newp"
                          placeholder="New Password"
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="inputNewC"
                        className="col-sm-2 control-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          name="confirmpassword"
                          className="form-control"
                          id="confirmp"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                        />
                        <div className="form-error1" />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-sm-offset-2 col-sm-10">
                        <input
                          type="submit"
                          className="btn btn-success"
                          name="submit"
                          value="Update Profile"
                          onClick={
                            handlesubmit
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AaProfile;
