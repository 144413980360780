import React, { useContext, useEffect, useState } from 'react'
import "../Css/Login.css"
import { global } from '../../Context/Context'
import mastiLive_logo from '../../assets/img/mastiLive_logo.png'
// import {toast} from 'react-toastify'


const Login = () => {

  const {login} = useContext(global)
  
  const [credentials ,setCredentials] = useState({
    number : '',
    password : ''
  })

 useEffect(()=>{
  if(sessionStorage.getItem('data'))
      window.location.href='/admin';
 },[]);
  const handleInputChange = (e)=>{
    setCredentials({
      ...credentials , 
      [e.target.name ] : e.target.value
    })
  }

  return (
    <>
      <div className="login-box">
        <div className="login-logo"> 
          <a href><img style={{width: '180px'}} src={mastiLive_logo} /></a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Sign in to Enter your Admin panel</p>
          <div className="form-error">
          </div>
          <form action="" method="" onSubmit={(e)=>{
            e.preventDefault()
            // console.log(credentials)
            login(credentials)
            // toast.success('testing')
          }}>
            <div className="form-group has-feedback">
              <input type="number" name="number" className="form-control" placeholder="Phone" value={credentials.phonenumber} onChange={handleInputChange}/>
              <span className="glyphicon glyphicon-phone form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="form-group has-feedback">
              <input type="password" name="password" className="form-control" placeholder="Password" value={credentials.password} onChange={handleInputChange}/>
              <span className="glyphicon glyphicon-lock form-control-feedback" />
              <div className="form-error1" />
            </div>
            <div className="row">
              <div className="col-xs-8">
                <label>
                  <input type="checkbox" /> Remember Me
                </label>
              </div>
              
              <div className="col-xs-4">
                <button type="submit" className="btn btn-primary btn-block btn-flat">Sign In</button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Login