import React from 'react'
import { Link,useNavigate } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'

const AddGift = () => {

    const navigate = useNavigate();
    const subbbb = (e) => {
        e.preventDefault();
        var a = $("input[name='title']").val();
        var b = $("input[name='image']").val();
        var c = $("input[name='coin']").val();
        let text = "This field cannot be empty";
        if(a == ''){
            $('.form-error1').html(text);
        }
        if(b == ''){
            $('.form-error2').html(text);
        }
        if(c == ''){
            $('.form-error3').html(text);
        }
        else  if(a != '' && b != '' && c != '')
        {
            let formData = new FormData(e.target); // Use e.target to access the form element
            $.ajax({
                url: `${process.env.REACT_APP_BASEURL}/admin/gifts/addGifts`,
                type: "post",
                dataType: "json",
                data: formData,
                contentType: false,
                processData: false,
                success: function(res) {
                if(res.success===1){
                    toast.success(res.message)
                    setTimeout(()=>{
                            navigate('/admin/manageGift')
                    },1000)
                }else{
                    toast.error(res.message)
                }
                }
            });
        }
        
    }

    return (
        <> 
            <section className="content-header">
                <h1>Add Gift</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li><Link to="/admin/manageGift">Manage Gifts</Link></li>
                    <li className="active">Add Gift</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form" method="post" onSubmit={subbbb} id="form" encType="multipart/form-data">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Add</h3>
                                </div>
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Title*</label>
                                        <input type="text" className="form-control" placeholder='title' name="title" onChange={()=>{
                                            $('.form-error1').html('');
                                        }}/>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Coin*</label>
                                        <input type="text" className="form-control" placeholder='coin' name="coin" onChange={()=>{
                                            $('.form-error3').html('');
                                        }} />
                                        <div className="form-error3 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Image*</label>
                                        <input accept="image/*" type="file" className="form-control" name="image" onChange={()=>{
                                            $('.form-error2').html('');
                                        }} />
                                        <div className="form-error2 text-danger"></div>
                                    </div>
                                    <div className="form-group">
                                        <button type="reset" className="btn btn-danger">Cancel</button>
                                        <input type="submit" className="btn btn-success pull-right" name="submit" defaultValue="Submit" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddGift