import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";

const RecieveCoinHistory = () => {
  const { received, rechargeRecievedHistorys } = useContext(global);
  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);
  useEffect(() => {
    if (!sessionStorage.getItem("coin")) {
      window.location.href = "/coin";
    }
    else
    rechargeRecievedHistorys({ userId: coinDetails.uniqueId });
  }, []);
 

  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Recieve Coin History</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/coin/dashboard">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Recieve Coin History</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>From</th>
                      <th>Coin</th>
                      <th>Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(received) &&
                      received.map((item, index) => {
                        let i = index + 1; // Start index from 1
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>Via Admin</td>
                            <td>
                              <label htmlFor="" className="label label-success">
                                {item.coin}
                              </label>
                            </td>
                            <td>{new Date(item?.createdAt).toDateString()}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* PHP session message removed */}
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default RecieveCoinHistory;
