import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
const AgencAdminManage = () => {
  const [agencyadimindata, setAgencyAdminData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const baseUrl = process.env.REACT_APP_BASEURL1;
  // const bdId = JSON.parse(sessionStorage.getItem("bd"));
  // const uniqueId = bdId ? bdId.uniqueId : "";
  // const createBy = bdId ? bdId.createBy : "";
    // console.log(bdId);
    // console.log(createBy);
    const manageagencadminApi = async () => {
    try {
      const agencyAdminResponse = await axios.get(
        `${baseUrl}/agencadminpanel/getall`
      
      );
      console.log(agencyAdminResponse);
      if (
        // agencyAdminResponse &&
        // agencyAdminResponse.data &&
        // agencyAdminResponse.data.data
        agencyAdminResponse
      ) {
        setAgencyAdminData(agencyAdminResponse.data.details);
        toast.success("Agency Admin list successfully retrieved");
      } else {
        toast.error("Failed to retrieve Agency Admin list");
      }
    } catch (error) {
      console.error("Error fetching Agency Admin list:", error);
      toast.error("Failed to get Agency Admin list");
    }
  };

  const deleteagencyadimin = async (id) => {
    try {
      console.log(id);
      const response = await axios.delete(
        `${baseUrl}/agencadminpanel/delete?id=${id}`
      );
      if (response.data.success == 1) {
        toast.success(response.data.message);
        manageagencadminApi();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting Agency Adimin:", error);
      toast.error("Failed to delete Agency Adimin");
    }
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = agencyadimindata.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(agencyadimindata.length / entriesPerPage);
      i++
    ) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button onClick={() => setCurrentPage(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    manageagencadminApi();
  }, []);
  // const [agencyadimindata, setAgencyAdminData] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [entriesPerPage, setEntriesPerPage] = useState(5);
  // const baseUrl = process.env.REACT_APP_BASEURL1;
  // const bdId = JSON.parse(sessionStorage.getItem("bd"));
  // const uniqueId = bdId ? bdId.uniqueId : "";
  // const createBy = bdId ? bdId.createBy : "";
  // console.log(bdId);
  // console.log(createBy);
  // const manageagencadminApi = async () => {
  //   try {
  //     const agencyAdminResponse = await axios.post(
  //       `${baseUrl}/agencadminpanel/getagencyadmin`,
  //       { createBy: uniqueId }
  //     );
  //     console.log(agencyAdminResponse);
  //     if (
  //       agencyAdminResponse &&
  //       agencyAdminResponse.data &&
  //       agencyAdminResponse.data.data
  //     ) {
  //       setAgencyAdminData(agencyAdminResponse.data.data);
  //       toast.success("Agency Admin list successfully retrieved");
  //     } else {
  //       toast.error("Failed to retrieve Agency Admin list");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Agency Admin list:", error);
  //     toast.error("Failed to get Agency Admin list");
  //   }
  // };

  // const deleteagencyadimin = async (id) => {
  //   try {
  //     console.log(id);
  //     const response = await axios.delete(
  //       `${baseUrl}/agencadminpanel/delete?id=${id}`
  //     );
  //     if (response.data.success == 1) {
  //       toast.success(response.data.message);
  //       manageagencadminApi();
  //     } else {
  //       toast.error(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting Agency Adimin:", error);
  //     toast.error("Failed to delete Agency Adimin");
  //   }
  // };

  // const handleEntriesPerPageChange = (e) => {
  //   setEntriesPerPage(parseInt(e.target.value));
  //   setCurrentPage(1);
  // };

  // // Pagination
  // const indexOfLastEntry = currentPage * entriesPerPage;
  // const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  // const currentEntries = agencyadimindata.slice(
  //   indexOfFirstEntry,
  //   indexOfLastEntry
  // );

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];
  //   for (
  //     let i = 1;
  //     i <= Math.ceil(agencyadimindata.length / entriesPerPage);
  //     i++
  //   ) {
  //     pageNumbers.push(
  //       <li
  //         key={i}
  //         className={`page-item ${currentPage === i ? "active" : ""}`}
  //       >
  //         <button onClick={() => setCurrentPage(i)} className="page-link">
  //           {i}
  //         </button>
  //       </li>
  //     );
  //   }
  //   return pageNumbers;
  // };
  // useEffect(() => {
  //   manageagencadminApi();
  // }, []);
  return (
    <>
      <section className="content-header">
        <h1>Manage Agency Admin</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Agency Admin</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                <Link
                  to="/admin/addagencyadmin"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add Agency Admin
                </Link>
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-5 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>

                  <div className="col-md-5" style={{ textAlign: "right" }}>
                    <div
                      className="main-data-single-field"
                      style={{ display: "inline-block", width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <input
                        type="text"
                        id="pname"
                        name="pname"
                        style={{
                          borderRadius: "4px",
                          borderStyle: "groove",
                          width: "150px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="success-message"></div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Aegncy Admin Code</th>
                      <th>Aegncy Admin Name</th>
                      <th>BD Id</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img src={`${item.image}`} alt="" width={"80px"} />
                        </td>
                        <td>{item.uniqueId}</td>
                        <td>{item.name}</td>
                        <td>{item.createBy}</td>
                        <td>{item.number}</td>
                        <td>
                          <div className="dropdown">
                            <button
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Action
                              <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right">
                              <li>
                                <Link
                                  to={`/admin/viewagencyadmin?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/admin/editagencyadmin?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => deleteagencyadimin(item._id)}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(agencyadimindata.length / entriesPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={
                            currentPage ===
                            Math.ceil(agencyadimindata.length / entriesPerPage)
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-7 text-right">
                    <p className="total-entries">
                      Total entries: <b>{agencyadimindata.length}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AgencAdminManage;
