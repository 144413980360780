import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { useSearchParams, useParams } from "react-router-dom";
import $ from "jquery";
import axios from "axios";

const ViewWidthdrawal = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [userData, setUserData] = useState([]);
  const [tag, setTag] = useState([]);
  const {
    // user,
    viewUsers,
    viewUser,
    viewTagg,
    viewTag,
    adminframes,
    viewadminframes,
    adminentryEffects,
    viewadminentryEffects,
  } = useContext(global);
  //   const { banStatus } = useContext(global);

  //   For query
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  // const response = async () => {
  //   const data1 = await axios.get(`${baseUrl}/admin/view_user?id=${id}`);
  //   // console.log(data1.data.details);
  //   setUserData(data1.data.details);
  // };
  // const settag = async (tag) => {
  //   if (tag !== null) {
  //     try {
  //       const data2 = await axios.post(`${baseUrl}/admin/usertag?id=${id}`, {
  //         myTag: tag,
  //         // banStatus: banstats,
  //       });
  //       console.log(data2);
  //     } catch (error) {
  //       console.error("Error updating user tag:", error);
  //       // Handle error as needed
  //     }
  //   } else {
  //     console.error("Tag value is null, skipping update");
  //   }
  // };
  // banstatus

  useEffect(() => {
    // response();
    viewTagg();
    viewadminframes();
    viewadminentryEffects();
    return () => {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const userId = params.get("id");
      viewUsers(userId);
    };
  }, []);

  const createdAtDate =
    userData && userData.createdAt ? new Date(userData.createdAt) : null;

  const formattedDate = createdAtDate
    ? createdAtDate.toISOString().split("T")[0]
    : "";
  // console.log(userData);

  const fetchAgencyDetails = async (value) => {
    try {
      const { data } = await axios.post(
        `https://mastishortss.com/video/admin/withdrawal/details`,
        value
      );
      // console.log(data);
      setUserData(data.details);
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(userData);

  useEffect(() => {
    fetchAgencyDetails({ userId: id });
  }, [id]);
  return (
    <>
      <section className="content-header">
        <h1>View User</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageUser">
              <i className="fa fa-user"></i> User
            </Link>
          </li>
          <li className="active">View User</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${baseUrl}${userData?.userDetails?.picture}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    {/* <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button> */}
                  </div>
                  <div>
                    {/* <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData?.username}{" "}
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Normal User
                      </span>
                    </div> */}
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {userData?.bio != "" ? (
                        <>{userData?.userDetails?.bio}</>
                      ) : (
                        <>N/A</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Username*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData
                            ? userData?.userDetails?.username
                            : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData ? userData?.userDetails?.email : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData ? userData?.userDetails?.name : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Acc No</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData
                            ? userData?.accountDetails?.accountNo
                            : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>IFSC CODE</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData
                            ? userData?.accountDetails?.ifscCode
                            : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Bank Name</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData
                            ? userData?.accountDetails?.bankName
                            : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>PAN Card</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData
                            ? userData?.accountDetails?.panNumber
                            : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Agency</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData ? userData?.agency?.uniqueId : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>BD</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={
                          userData ? userData?.agency?.bd : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>Agency Admin</label>
                      <input
                        type="text"
                        className="form-control w-100"
                        defaultValue={
                          userData ? userData?.agency?.admin : "Not found"
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  {/* Official Tag */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Official Tags
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                        onChange={(e) => {
                          const selectedTagId = e.target.value;
                          setTag(selectedTagId);
                          // settag(selectedTagId);
                        }}
                      >
                        <option disabled selected>
                          Select Tag
                        </option>
                        {Array.isArray(viewTag) &&
                          viewTag.map((item) => {
                            // {
                            //   console.log(item);
                            // }
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div> */}
                  {/* UserId(Ban/ Unban) */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tagggg"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        UserId(Ban/ Unban)
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tagggg"
                        style={{ border: "1px solid #ef5d84" }}
                        onChange={() => {
                          const selectedBanStatus = $("#o-tagggg").val();
                          // setBanStatus(selectedBanStatus);
                        }}
                      >
                        <option disabled>UserId(Ban/ Unban)</option>
                        <option value={0} selected={viewUser.banStatus === "0"}>
                          unbanned
                        </option>
                        <option value={1} selected={viewUser.banStatus === "1"}>
                          banned
                        </option>
                      </select>
                    </div>
                  </div> */}
                  {/* Device Id(Ban/ Unban) */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Device Id(Ban/ Unban)
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Device Id Banned
                        </option>
                        <option value={1}>Device Id Not Banned</option>
                      </select>
                    </div>
                  </div> */}
                  {/* Send Frame */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Send Frame
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Select Frame
                        </option>
                        {Array.isArray(adminframes) &&
                          adminframes.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div> */}
                  {/* Send Garage */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="o-tag"
                        style={{ color: "#ef5d84", fontSize: "15px" }}
                      >
                        Send Garage
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        id="o-tag"
                        style={{ border: "1px solid #ef5d84" }}
                      >
                        <option disabled selected>
                          Select EntryEffect
                        </option>
                        {Array.isArray(adminentryEffects) &&
                          adminentryEffects.map((item) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ViewWidthdrawal;
