import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import { get } from "jquery";
const HostRecord = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { getHosts, totalHosts, hostrecord, hostRecord } = useContext(global);
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);
  let id = newAgency.uniqueId;
  console.log(hostRecord);
  useEffect(() => {
    getHosts(id);
  }, []);
  useEffect(() => {
    hostrecord();
  }, []);
// console.log(getHosts);
// console.log(hostRecord);
// console.log(hostrecord);
  return (
    <>
      <section className="content-header">
        <h1>Host Record</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host Record</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      // value={entriesPerPage}
                      // onChange={(e) =>
                      // setEntriesPerPage(parseInt(e.target.value))
                      // }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Host Name</th>
                      <th>Username</th>
                      <th>Minutes</th>
                      <th>Diamonds</th>
                      <th>Join Date</th>
                      <th>Record</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {hostRecord.map((host, i) => (
                      <tr key={host._id}>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={`${host.image}`}
                            alt=""
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        </td>
                        <td>{host.channelName}</td>

                        <td>{host.userId.username}</td>
                        <td>{host.totalTime}</td>
                        <td>{host.diamond}</td>
                        <td>{host.createDate}</td>

                        <td>
                          <span
                          className={`label  ${host.
                            status == "archive"
                              ? "label-info"
                              
                              : "label-success"
                          }`
                          }
                          >
                            {host.status =="archive"
                              ? "archive"
                              
                              : "live"}
                          </span>
                        </td>
                        <td>
                          <ul
                            className="admin-action btn btn-default"
                            style={{
                              backgroundColor: "#f4f4f4",
                              color: "#fff !important",
                            }}
                          >
                            <li className="dropdown">
                              <a
                                className="dropdown-toggle"
                                style={{ color: "black" }}
                                data-toggle="dropdown"
                                href="#"
                                aria-expanded="false"
                              >
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                {/* {status === 0 ? (
                                  <>
                                    <li
                                      role="presentation"
                                      onClick={() => {
                                        aprrovedHost({
                                          id: host._id,
                                          status: 1,
                                        });
                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Aprroved
                                      </Link>
                                    </li>
                                    <li
                                      role="presentation"
                                      onClick={() => {
                                        aprrovedHost({
                                          id: host._id,
                                          status: 2,
                                        });
                                      }}
                                    >
                                      <Link role="menuitem" tabIndex={-1} to="">
                                        Reject
                                      </Link>
                                    </li>
                                  </>
                                ) : status === 1 ? (
                                  <li
                                    role="presentation"
                                    onClick={() => {
                                      aprrovedHost({
                                        id: host._id,
                                        status: 2,
                                      });
                                    }}
                                  >
                                    <Link role="menuitem" tabIndex={-1} to="">
                                      Reject
                                    </Link>
                                  </li>
                                ) : (
                                  <li
                                    role="presentation"
                                    onClick={() => {
                                      aprrovedHost({
                                        id: host._id,
                                        status: 1,
                                      });
                                    }}
                                  >
                                    <Link role="menuitem" tabIndex={-1} to="">
                                      Aprroved
                                    </Link>
                                  </li>
                                )} */}
                                {/* <li role="presentation">
                                  <Link role="menuitem" tabIndex={-1} to="">
                                    {status === 0 && "Reject"}
                                  </Link>
                                </li> */}
                                {/* <li role="presentation">
                                  <Link role="menuitem" tabIndex={-1} to="">
                                    Delete
                                  </Link>
                                </li> */}
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div>
                  <ul className="pagination">
                    <li>
                      <a href="#">Previous</a>
                    </li>
                    {/* Example pagination links */}
                    <li className="active">
                      <a href="#">1</a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    {/* Add more pagination links as needed */}
                    <li>
                      <a href="#">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostRecord;
