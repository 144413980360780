import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const PushMessage = () => {
  /////////////////////////
  const [queryparams, setqueryparams] = useState(0);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/admin/notification/send?type=${queryparams}`,
        {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let data = await response.json();
      console.log(data);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      toast.success("Notification sent successfully!");
      setFormData({
        title: "",
        description: "",
      });
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error(error.message);
    }
  };

  const handleUserChange = (e) => {
    const user = document.getElementById("user").value;
    const replace = document.getElementById("replace");
    replace.style.display = user === "1" ? "none" : "block";
    setqueryparams(e.target.value);
  };
  console.log(queryparams);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  /////////////////////////

  return (
    <>
      <section className="content-header">
        <h1>Send Notification</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Send Notification</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Title</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label htmlFor="sel1">Select User:</label>
                    <select
                      className="form-control"
                      id="user"
                      name="userId"
                      onChange={handleUserChange}
                    >
                      <option value>select user</option>
                      <option value={0}>Paticuler User</option>
                      <option value={1}>All Users</option>
                    </select>
                    <span className="form-error1" />
                  </div>
                  <div
                    className="form-group"
                    id="replace"
                    style={{ display: "none" }}
                  >
                    <label htmlFor="sel1">User Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      name="userId"
                      value={formData.userId}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Title</label>
                    <input
                      name="title"
                      className="form-control"
                      value={formData.title}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      name="description"
                      className="form-control"
                      value={formData.description}
                      onChange={handleChange}
                    />
                    <div className="form-error1" />
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default PushMessage;
