import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { global } from "../../Context/Context";
// import '../Css/Header.css'

const BdDashboard = () => {
  const location = useLocation();
  useEffect(() => {
    if (!sessionStorage.getItem("bd")) {
      window.location.href = "/bd";
    }
  }, []);
  const { fetchHosts, fetchHost } = useContext(global);

  // const agency = sessionStorage.getItem("agency");
  // const newAgency = JSON.parse(agency);
  // let id = newAgency.uniqueId;
  // console.log(id);

  useEffect(() => {
    const agency = sessionStorage.getItem("bd");
    if (agency) {
      const newAgency = JSON.parse(agency);
      let id = newAgency.uniqueId;
      fetchHosts(id);
    } else {
      window.location.href = "/bd";
    }
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>Dashboard</h1>
        <ol className="breadcrumb">
          <li>
            <a href="#">Home</a>
          </li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        {/* Info boxes */}
        <div className="row">
          {/* First Info Box */}
          {!location.pathname === "/agency/dashboard" && (
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div
                className="info-box green-bg"
                style={{ background: "#06690d", color: "#fff" }}
              >
                <span className="info-box-icon">
                  <i className="fa fa-user" />
                </span>
                <div className="info-box-content">
                  <span className="info-box-text">
                    <b>Total User</b>
                  </span>
                  <span className="info-box-number">100</span>
                </div>
              </div>
            </div>
          )}
          {/* Second Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box blue-bg"
              style={{ background: "#058d9e", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Approved Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.accepted}</span>
              </div>
            </div>
          </div>
          {/* Third Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box red-bg"
              style={{ background: "#c72a16", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Pending Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.pending}</span>
              </div>
            </div>
          </div>
          {/* Fourth Info Box */}
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div
              className="info-box yellow-bg"
              style={{ background: "#06690d", color: "#fff" }}
            >
              <span className="info-box-icon">
                <i className="fa fa-user" />
              </span>
              <div className="info-box-content">
                <span className="info-box-text">
                  <b>Total Rejected Host</b>
                </span>
                <span className="info-box-number">{fetchHost?.rejected}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BdDashboard;
