import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const ViewAgencyAdmin = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("id");
  // const [agencyDataDetails, setAgencyDataDetails] = useState([]);
  const [agencyadmindata, setAgencyAdmindata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [agencyDataDetails, setAgencyDataDetails] = useState([]);

  const edit = async (id) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/agencadminpanel/getone?id=${id}`
      );
      setAgencyAdmindata(data.data);
      toast.success(data.message);
    } catch (error) {
      console.error("Error edit /agencadmin:", error);
      toast.error("Failed to edit /agencadmin");
    }
  };
console.log(agencyDataDetails)
  useEffect(() => {
    if (userId) {
      edit(userId);
    }
  }, [userId]);
  console.log(agencyadmindata.uniqueId)
  const InAgencyAdmin = async () => {
    let { data } = await axios.post(`https://mastishortss.com/video/agencyadmin/allagency`,
      // `${process.env.REACT_APP_BASEURL1}/agencyadmin//allagency`,
    {agencyadmin: agencyadmindata.uniqueId}
    );
    setAgencyDataDetails(data.details);
    console.log(data);
  };
  // const agencyDetails = JSON.parse(sessionStorage.getItem("agencyadmin"));
  // const dataValue = {
  //   admin: agencyDetails.uniqueId,
  // };
  // console.log(dataValue);
  useEffect(() => {
    InAgencyAdmin();
   
  },[agencyadmindata]);

  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const currentUsers = agencyDataDetails.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers = [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  const totalPages = Math.ceil(agencyDataDetails.length / entriesPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };
  return (
    <>
      <section className="content-header">
        <h1>View Agency Admin</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/bd/viewagencyadmin">Manage Agency Admin</Link>
          </li>
          <li className="active">View Agency Admin</li>
        </ol>
      </section>
      <section className="content">
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-md-10 col-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div
                  className="box-header with-border"
                  style={{ display: "flex" }}
                >
                  <div style={{ position: "relative" }}>
                    <img
                      src={`${baseUrl}${agencyadmindata.image}`}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                      }}
                      id="pro_img"
                    />
                    <button
                      type="button"
                      id="edit_btn"
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-5px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square" />
                    </button>
                  </div>
                  <div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      <span
                        className=" label label-warning"
                        style={{ fontSize: "10px" }}
                        aria-label="user type"
                      >
                        Agency Admin
                      </span>
                    </div>
                    <div style={{ marginLeft: "15px", fontSize: "20px" }}>
                      {agencyadmindata?.name}
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Agency Admin Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={agencyadmindata?.name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Agency Admin Code*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={agencyadmindata?.uniqueId}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={agencyadmindata?.number}
                        readOnly
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                <div className="form-group">
                  <label>Join Date*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={bddata?.joinDate}
                    readOnly
                  />
                </div>
              </div> */}
                  {/* <div className="col-sm-6">
                <div className="form-group">
                  <label>Hosts*</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={bddata?.hosts}
                    readOnly
                  />
                </div>
              </div> */}
                  {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>Password*</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={agencyadmindata?.password}
                        // readOnly
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Agency Code</th>
                      <th>Agency Name</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {Array.isArray(currentUsers) &&
                      currentUsers.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>
                              <img
                                src={`${baseUrl}${item.image}`}
                                alt=""
                                width={"80px"}
                              />
                            </td>
                            <td>{item.uniqueId}</td>
                            <td>{item.name}</td>
                            <td>{item.number}</td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/bd/viewAgency?id=${item._id}`}
                                      >
                                        View
                                      </Link>
                                    </li>
                                    {/* <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        to={`/admin/editAgency?id=${item._id}`}
                                      >
                                        Edit
                                      </Link>
                                    </li> */}
                                    {/* <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to delete this item?"
                                          );
                                          if (confirmDelete) {
                                            $.ajax({
                                              url: `${process.env.REACT_APP_BASEURL}/admin/coinPanel/deleteCoinPanel/${item._id}`,
                                              type: "delete",
                                              dataType: "json",
                                              success: function (res) {
                                                if (res.success === 1) {
                                                  toast.success(res.message);
                                                  setDe(de + 1);
                                                } else {
                                                  toast.error(res.message);
                                                }
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li> */}
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Repeat rows as needed */}
                  </tbody>
                </table>
      </section>
    </>
  );
};

export default ViewAgencyAdmin;
