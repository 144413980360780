import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const ManageBd = () => {
  const [bddata, setBddata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const baseUrl = process.env.REACT_APP_BASEURL1;

  const manageBdApi = async () => {
    try {
      const bdresponse = await axios.get(`${baseUrl}/bdpanel/getbd`);
      if (bdresponse && bdresponse.data && bdresponse.data.data) {
        setBddata(bdresponse.data.data);
        toast.success("BD list successfully retrieved");
      } else {
        toast.error("Failed to retrieve BD list");
      }
    } catch (error) {
      console.error("Error fetching BD list:", error);
      toast.error("Failed to get BD list");
    }
  };

  const deleteBd = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/bdpanel/delete?id=${id}`);
      if (response.data.success === 1) {
        toast.success(response.data.message);
        manageBdApi(); // Refresh BD list after deletion
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting BD:", error);
      toast.error("Failed to delete BD");
    }
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when changing entries per page
  };

  // Pagination
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = bddata.slice(indexOfFirstEntry, indexOfLastEntry);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(bddata.length / entriesPerPage); i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button onClick={() => setCurrentPage(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    manageBdApi();
  }, []);
  return (
    <>
      <section className="content-header">
        <h1>Manage BD</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage BD</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                <Link
                  to="/admin/addBd"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add BD
                </Link>
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="row" style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-5 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>

                  <div className="col-md-5" style={{ textAlign: "right" }}>
                    <div
                      className="main-data-single-field"
                      style={{ display: "inline-block", width: "fit-content" }}
                    >
                      <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                        Search
                      </span>
                      <input
                        type="text"
                        id="pname"
                        name="pname"
                        style={{
                          borderRadius: "4px",
                          borderStyle: "groove",
                          width: "150px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="success-message"></div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>BD Code</th>
                      <th>BD Name</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img src={`${item.image}`} alt="" width={"80px"} />
                        </td>
                        <td>{item.uniqueId}</td>
                        <td>{item.name}</td>
                        <td>{item.number}</td>
                        <td>
                          <div className="dropdown">
                            <button
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Action
                              <span className="caret"></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right">
                              <li>
                                <Link
                                  to={`/admin/viewBd/?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  View
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={`/admin/editBD?id=${item._id}`}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => deleteBd(item._id)}
                                  role="menuitem"
                                  tabIndex={-1}
                                >
                                  Delete
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row">
                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {renderPageNumbers()}
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(bddata.length / entriesPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={
                            currentPage ===
                            Math.ceil(bddata.length / entriesPerPage)
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-7 text-right">
                    <p className="total-entries">
                      Total entries: <b>{bddata.length}</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageBd;
